<template>
  <div class="container-fluid mt-4">
    <a-page-header class="demo-page-header" style="padding: 0" title="Salaries">
    </a-page-header>

    <a-row class="my-20" justify="space-between" align="middle">
      <a-col>
        <a-button @click="togglemonth(0)"><LeftOutlined /></a-button>
      </a-col>
      <a-col>
        <a-typography-title
          :level="3"
          class="text-center"
          style="margin-bottom: 0"
        >
          {{ $filters.formatTime(currentTime, "YYYY-MM") }}
        </a-typography-title>
      </a-col>
      <a-col>
        <a-button @click="togglemonth(1)"><RightOutlined /></a-button>
      </a-col>
    </a-row>

    <a-table
      class="table"
      :loading="loading"
      :columns="columns"
      :data-source="list"
      :pagination="{
        hideOnSinglePage: true,
        total: total,
        current: currentPage,
        defaultPageSize: limit,
      }"
      @change="tableChange"
    >
      <template #name="{ text }">
        <a>{{ text }}</a>
      </template>
      <template #total="{ text }">
        <a>{{ Number(text / 100).toFixed(2) }}</a>
      </template>
      <template #daySalary="{ text }">
        <a>{{ Number(text / 100).toFixed(2) }}</a>
      </template>
      <template #CompanyPart="{ text }">
        <a>{{ text > 0 ? Number(text / 100).toFixed(2) : '' }}</a>
      </template>
      <template #EmployeePart="{ text }">
        <a>{{ text > 0 ? Number(text / 100).toFixed(2) : '' }}</a>
      </template>
      <template #leaveDeduct="{ text }">
        <a>{{ text > 0 ? Number(text / 100).toFixed(2) : '' }}</a>
      </template>
      <template #actualPayOff="{ text }">
        <a>{{ Number(text / 100).toFixed(2) }}</a>
      </template>
      <template #personalIncomeTax="{ text }">
        <a>{{ text > 0 ? Number(text / 100).toFixed(2) : '' }}</a>
      </template>
    </a-table>
  </div>

</template>

<script>
import { computed, getCurrentInstance, reactive, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { LeftOutlined, RightOutlined } from "@ant-design/icons-vue";

const columns = [
  {
    title: "人员",
    dataIndex: "userName",
    ellipsis: true,
  },
  {
    title: "有效工作日(天)",
    dataIndex: "workdays",
    ellipsis: true,
  },
  {
    title: "实际工作日(天)",
    dataIndex: "actualWorkdays",
    ellipsis: true,
  },
  {
    title: "税前月薪(元)",
    dataIndex: "total",
    ellipsis: true,
    slots: { customRender: "total" },
  },
  {
    title: "税前日薪(元)",
    dataIndex: "daySalary",
    ellipsis: true,
    slots: { customRender: "daySalary" },
  },
  {
    title: "社保个人部分(元)",
    dataIndex: "socialSecurityEmployeePart",
    ellipsis: true,
    slots: { customRender: "EmployeePart" },
  },
  {
    title: "个人所得税(元)",
    dataIndex: "personalIncomeTax",
    ellipsis: true,
    slots: { customRender: "personalIncomeTax" },
  },
  {
    title: "请假概要",
    dataIndex: "leaveSummary",
    ellipsis: true,
    width: 200,
  },
  {
    title: "请假扣除(元)",
    dataIndex: "leaveDeduct",
    ellipsis: true,
    slots: { customRender: "leaveDeduct" },
  },
  {
    title: "税后实发(元)",
    dataIndex: "actualPayoff",
    ellipsis: true,
    slots: { customRender: "actualPayOff" },
  },
  {
    title: "薪水概要",
    dataIndex: "salarySummary",
    ellipsis: true,
    width: 300,
  },
];
export default {
  components: {
    LeftOutlined,
    RightOutlined,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const v = reactive({
      list: [],
      page: 1,
      limit: 10,
      total: 0,
      currentPage: 1,
      columns,

      siderStatus: false,
      loading: false,

      showModal: false,
      salary: null,

      currentTime: "",
    });

    watch(
      () => v.currentTime,
      () => {
          getlist();
      }
    );

    v.currentTime = new Date().getTime();

    const userInfo = computed(() => store.state.userInfo);

    async function getlist(id) {
      v.loading = true;

      let res;
      try {
        res = await proxy.$axios.get(`/api/v1/users/salaries`, {
          month: proxy.$filters.formatTime(v.currentTime, "YYYYMM"),
          page: v.page,
          limit: v.limit,
        });
      } catch (err) {}

      v.loading = false;
      if (res && res.data) {
        v.list = res.data.items;
        v.total = res.data.total;
        v.list.forEach((item, index) => {
          item.key = index;
        });
      }
    }

    function tableChange(pagination) {
      v.page = pagination.current;
      v.currentPage = pagination.current;
      getlist(userInfo.value.id);
    }

    function togglemonth(type) {
      if (type) {
        v.currentTime += 86400000 * 30;
      } else {
        v.currentTime = v.currentTime - 86400000 * 30;
      }
    }

    return {
      ...toRefs(v),
      userInfo,
      tableChange,
      togglemonth,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/style/mixins";

.content {
  background-color: $color-white;
  padding: 15px;
}
.footer {
  text-align: center;
}
.table {
  margin-top: 20px;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(30px);
  opacity: 0;
}
</style>
